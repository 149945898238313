import React, { Component } from 'react';


import fire from  "../../config/shit"

import SPAHome from '../../components/spacomponents/SPAHome';

import SPALogin from '../../components/spacomponents/SPALogin';

import SPAHeader from '../../components/spacomponents/SPAHeader';



import '../eng/index.css';

import NotificationSystem from 'react-notification-system';
import Wave from '../../components/Wave';


class SPAINDEX extends React.Component {
    constructor(props) {
    super(props)
    this.state = ({
        user: null,
    });
    this.authListener = this.authListener.bind(this);
    }

    componentDidMount() {
        this.authListener()

    }

    authListener() {
        fire.auth().onAuthStateChanged((user) => {
            console.log(user);
            if (user) {
                this.setState({ user });
                localStorage.setItem('user',user.uid);
            } else {
                this.setState({ user: null });
                localStorage.removeItem('user');
            }
        });
    }

    render() {

        

        if (this.state.user != null ) {

            return (
                <div>
                <SPAHeader auth="true" />
                    <div className="Hero">
                        <div className="HeroGroup">
                            <div>
                                <SPAHome />
                                <Wave />
                            </div>
                        </div>
                    </div>
                </div>
            )

        } else if (this.state.user == null) {

            return (
                <div>
                <SPAHeader auth="false" />
                    <div className="Hero">
                        <div className="HeroGroup">
                            <div>
                                <SPALogin />
                                <Wave />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

       

    }
}

export default SPAINDEX;