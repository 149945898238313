import React from 'react';
import SmallCard from '../../components/SmallCard';
import Modal from 'react-responsive-modal';
import GlossarySpa from './Recursos/GlossarySpa';
import TrapsSpa from './Recursos/TrapsSpa';
import CodeOfHonorSpa from './Recursos/CodeOfHonorSpa';
import PhoneticsSpa from './Recursos/PhoneticsSpa';



class Recursos extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
          open: false,
        }

        
  
    }


    onOpenModal = (id) => {
          this.setState({ 
            open:{
            [id]: true
          } 
        });
      };


      onCloseModal = (id) => {
        this.setState({ 
          open: {
            [id]: false
          }
        });
      };


    render() {

      const { open } = this.state;

      let data = [
        {
          id: 1,
          title: "Glosario",
          image:require('../../Images/wallpaper.jpg'),
          logo:require('../../Images/Res1.png'),
          comp:<GlossarySpa />
        },
        {
          id: 2,
          title: "Traps(Trampas)",
          image:require('../../Images/wallpaper.jpg'),
          logo:require('../../Images/Res2.png'),
          comp:<TrapsSpa />
        },
        {
          id: 3,
          title: "Codigo de Honor",
          image:require('../../Images/wallpaper.jpg'),
          logo:require('../../Images/Res3.png'),
          comp:<CodeOfHonorSpa />
        },
        {
          id: 4,
          title: "Fonética",
          image:require('../../Images/wallpaper.jpg'),
          logo:require('../../Images/Res4.png'),
          comp:<PhoneticsSpa />
        }
        
      ];
        

        return(
            
            <div className="SmallCardGroup">
                {data.map(function(i, index) {
              return (

                  <div key={i.id}>
                  
                  <a key={i.id} onClick={this.onOpenModal.bind(this, i.id)} >
                    <SmallCard 
                            logo={i.logo}
                            title={i.title}
                            image={data.image}>  
                    </SmallCard>
                  </a>

                  <Modal className="Modal" open={open[i.id]} onClose={this.onCloseModal}  >
                  
                  {i.comp}


                </Modal>

            </div>
                  
                 
              );
            }, this)}
         

                        
        
                </div>
        )
    }
}

export default Recursos;