import "./ResourcesSpa.css";

import React from "react";

class CodeOfHonorSpa extends React.Component {
  render() {
    return (
      <div className="Modal">
        <div className="ModalHead">
          <img
            className="ModalIcon"
            src={require("../../../Images/Res3.png").default}
          />
          <h1 className="ModalTitle">Codigo de Honor</h1>
        </div>
        <div className="ModalBody">
          <div id="whycode" className="ModalBodyCard">
            <ul>
              <li key="1" id="ModalListLiResource">
                Utilización oportuna y eficaz de la comunicación
              </li>
              <li key="2" id="ModalListLiResource">
                Tratar a todos con respeto
              </li>
              <li key="3" id="ModalListLiResource">
                Estar dispuestos a llamar y ser llamado
              </li>
              <li key="4" id="ModalListLiResource">
                Tratar directamente
              </li>
              <li key="5" id="ModalListLiResource">
                Ser el ejemplo
              </li>
              <li key="6" id="ModalListLiResource">
                Aprenda, comparta, enseñe
              </li>
              <li key="7" id="ModalListLiResource">
                Llegue a tiempo
              </li>
              <li key="8" id="ModalListLiResource">
                Haga lo que usted dice
              </li>
              <li key="9" id="ModalListLiResource">
                Termine lo que empiece
              </li>
              <li key="10" id="ModalListLiResource">
                Diga “Por favor” y “Gracias”
              </li>
              <li key="11" id="ModalListLiResource">
                Celebre todos los éxitos
              </li>
              <li key="12" id="ModalListLiResource">
                Diviértase!!
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default CodeOfHonorSpa;
