import React from 'react'
import Link, { replace } from 'gatsby-link'

import logo from '../../Images/logo.png';
import fire from '../../config/shit';

import './SPAHeader.css';


class SPAHeader extends React.Component {
    constructor(props) {
        super(props)

        this.handleLogOut = this.handleLogOut.bind(this);
        this.state = {
            hasScrolled: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    handleScroll = (event) => {

        const scrollTop = window.pageYOffset

        if (scrollTop > 50) {
            this.setState({ hasScrolled: true })

        } else {
            this.setState({ hasScrolled: false })
        }

    }

    handleLogOut() {
        fire.auth().signOut().then(u => {
            replace('/spa')
        });

    }



    render() {

        if (this.props.auth == "true") {

            return (

                <div className={this.state.hasScrolled ? 'Header HeaderScrolled' : 'Header'}>
                    <div className="HeaderGroup">
                        <Link to="/spa"><img className="HeaderLogo" src={logo} width="30"></img></Link>
                        <Link to="/spa">Bienvenidos</Link>
                        <Link to="/spa/herramientas">Herramientas</Link>
                        <Link to="/spa/cuenta">Cuenta</Link>
                        <Link to="/"><button className="HeaderButton" onClick={this.handleLogOut}>Cerrar Sesion</button></Link>
                    </div>
                </div>
            );

        } else if (this.props.auth == "false") {

            return (

                <div className={this.state.hasScrolled ? 'Header HeaderScrolled' : 'Header'}>
                    <div className="HeaderGroup">
                        <Link to="/"><img className="HeaderLogo" src={logo} width="30" /></Link>
                        <Link to="/">Bienvenidos</Link>


                    </div>
                </div>
            );


        }


    }
}

export default SPAHeader;