import "./ResourcesSpa.css";

import React from "react";

class GlossarySpa extends React.Component {
  render() {
    return (
      <div className="Modal">
        <div className="ModalHead">
          <img
            className="ModalIcon"
            src={require("../../../Images/Res1.png").default}
          />
          <h1 className="ModalTitle">Glosario</h1>
        </div>
        <div className="ModalBody">
          <div id="why" className="ModalBodyCard">
            <h3 className="ModalBodyTitleResource">Lider</h3>
            <hr className="ModalHrLine" />
            <p id="ModalResourceP">
              Es un líder al tomar toda la responsabilidad por su propio
              comportamiento e influir positivamente en las acciones y
              comportamientos de los demás
            </p>
          </div>
          <div id="when" className="ModalBodyCard">
            <h3 className="ModalBodyTitleResource">
              Responsabilidad Proactiva®
            </h3>
            <hr className="ModalHrLine" />
            <p id="ModalResourceP">
              Voluntad personal para (1) aceptar toda la responsabilidad por
              todas las acciones tomadas y (2) para anticipar y actuar con
              respecto a los retos y oportunidades
            </p>
          </div>
          <div id="how" className="ModalBodyCard">
            <h3 className="ModalBodyTitleResource">
              Preceptos De Practicing Perfection®
            </h3>
            <hr className="ModalHrLine" />
            <ul>
              <li key="1" id="ModalListLiResource">
                Las cosas son como son porque asi llegaron a ser
              </li>
              <li key="2" id="ModalListLiResource">
                84 a 94 por ciento de todos los errores humanos pueden
                atribuirse directamente a problemas de proceso y organización
              </li>
              <li key="3" id="ModalListLiResource">
                Las personas vienen a trabajar queriendo hacer un buen trabajo
              </li>
              <li key="4" id="ModalListLiResource">
                Las personas que hacen el trabajo son las que tienen las
                respuestas
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default GlossarySpa;
