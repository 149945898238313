import "./ResourcesSpa.css";

import React from "react";

class TrapsSpa extends React.Component {
  render() {
    return (
      <div className="Modal">
        <div className="ModalHead">
          <img
            className="ModalIcon"
            src={require("../../../Images/Res2.png").default}
          />
          <h1 className="ModalTitle">TRAPS(TRAMPAS)</h1>
        </div>
        <div className="ModalBody">
          <div id="why" className="ModalBodyCard">
            <h3 className="ModalBodyTitleResource">
              Condiciones del entorno laboral que hacen que sea más probable que
              las personas cometan errores.<br></br> <br></br>Las diez trampas
              de errores humanos predominantes incluyen:
            </h3>
            <hr className="ModalHrLine" />
            <ul>
              <li key="1" id="ModalListLiResource">
                Presión por Tiempo
              </li>
              <li key="2" id="ModalListLiResource">
                Distracciones/Interrupciones
              </li>
              <li key="3" id="ModalListLiResource">
                Tareas Múltiples
              </li>
              <li key="4" id="ModalListLiResource">
                Exceso de Confianza
              </li>
              <li key="5" id="ModalListLiResource">
                Orientación Vaga o Interpretativa
              </li>
              <li key="6" id="ModalListLiResource">
                Turno de Trabajo Primer/Ultimo
              </li>
              <li key="7" id="ModalListLiResource">
                Presión de Compañeros
              </li>
              <li key="8" id="ModalListLiResource">
                {" "}
                Cambio/No-Normal
              </li>
              <li key="9" id="ModalListLiResource">
                Entorno Físico
              </li>
              <li key="10" id="ModalListLiResource">
                Estrés Mental
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default TrapsSpa;
