import React from 'react';
import fire from "../../config/shit";
import firebase from "firebase";
import NotificationSystem from 'react-notification-system';
import './ForgotFormSpa.css';

class ForgotFormSpa extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
    this.resetPassword = this.resetPassword.bind(this);

    this.state = {
      _notificationSystem: null,
      email: ""

    }


  }


  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });


  }

  resetPassword(e) {
    e.preventDefault();

    var emailAddress = this.state.email;

    fire.auth().sendPasswordResetEmail(emailAddress).then(() => {
      console.log('email sent!');

      this._notificationSystem.addNotification({
        message: "Revise su correo",
        level: 'success',
        position: 'tc',
        title: 'Success',



      });
    }).catch(error => {
      // An error happened.
      var errormessage = String(error);
      console.log(errormessage)

      this._notificationSystem.addNotification({
        message: errormessage,
        level: 'error',
        position: 'tc',
        title: 'Verifique su Información',



      });



    });

  }



  render() {

    return (

      <form className="Form">
        <div className="FormGroup">
          <label className="FormLabel">Correo Electronico</label>
          <input
            className="FormInput"
            value={this.state.email}
            onChange={this.handleChange}
            type="email"
            name="email"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Correo"
          />
        </div>



        <button className="ForgotPassword" onClick={this.resetPassword}>Enviar Contraseña</button>
        <NotificationSystem ref="notificationSystem" />
      </form>
    )
  }
}

export default ForgotFormSpa;